.bg {
  background-color: #191fcb;
}

.logo-img {
  width: 58%;
}

.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}
.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

.bg1 {
  background: linear-gradient(45deg, #17449e, #000000cf);
}
.bg2 {
  background-color: aliceblue;
}

.trackdiv_home {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
}

.trackdiv_main {
  width: 80%;
  height: 25vh;
  /* background: #eaf6fb; */
  padding: 2vh;
  /* border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  margin-top: 3vh;
  border-radius: 1vh;
}

.trackdiv_main .spaan_title {
  color: #191970;
  font-size: 2rem;
  font-weight: 600;
}
.trackdiv_main p {
  color: #191970;
  font-size: 1.4rem;
  margin: 0 !important;
  padding: 0 !important;
}

.input_divs {
  width: 50%;
  margin-top: 3vh;
}

.input_divs .button_track {
  width: 100%;
  background: #191970 !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
}

.main_fragnent_container {
  height: 100%;
  /* height: 86vh;
  overflow-y: scroll; */
  /* background-color: #cfcdfe; */
}

.profile_container_header {
  background: #fff;
  width: 100%;
  height: 10vh;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vh;
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.profile_co_header_left {
  width: 30%;
  height: 100%;
  padding: 0 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.profile_co_header_left span {
  font-size: 0.9rem;
  color: #191970;
  font-weight: 700;
  text-align: center;
  line-height: 17px;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
}

.profile_co_header_right {
  width: 70%;
  height: 100%;
  padding: 0 1vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1vh;
}

.ship_ment_deatils {
  background: #eaf6fb;
  width: 100%;
  padding: 1vh;
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 3vh;
}

.ship_div_main {
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.pack_details {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.pack_details .pack_box1 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pack_details .pack_box2 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.status_box {
  width: 30vh;
  height: 70%;
  background: #00d2ff;
}

.pack_box1_ttitle {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1vh;
}
.pack_box1_ttitle span {
  color: #191970;
  font-size: 1.5rem;
  font-weight: 600;
}
.status_box1_ttitl {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1vh;
  background: #191970;
}
.status_box1_ttitl span {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}
.status_box2_detai {
  width: 100%;
  height: 70%;
  display: flex;
  padding-left: 1vh;
  background: #fff;
  flex-direction: column;
}

.pack_box1_cat {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1vh;
}
.pack_box1_cat span {
  color: #191970;
  font-size: 1.5rem;
  font-weight: 500;
  padding-left: 2vh;
}

.pack_box2_ti {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pack_box2_ti span {
  color: #191970;
  font-size: 1.1rem;
  font-weight: 700;
}
.pack_box2_tititle {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pack_box2_tititle span {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
}

.margins_tops {
  margin-top: 5vh;
}
.trav_details {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trav_display_main {
  width: 80%;
  height: 7vh;
  /* background: #00d2ff; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.trav_display_main span {
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
}

.fast_imag {
  width: 10vh;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fast_imag img {
  width: 100%;
  height: 100%;
}

.div_centerss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline_container {
  width: 90%;
  margin-top: 4vh;
}

.desktop_version {
  display: block !important;
}
.mobile_version {
  display: none !important;
}
.img-log {
  display: none;
}
#courier {
  color: #191970;
  font-size: 14px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .desktop_version {
    display: none !important;
  }
  .mobile_version {
    display: block !important;
  }
  .profile_container_header {
    height: 14vh;
    display: flex;
    flex-direction: column;
  }
  .profile_co_header_left {
    width: 100%;
    height: 100%;
    padding: 0 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .profile_co_header_right {
    width: 100%;
    height: 100%;
  }
  .ship_div_main {
    width: 100%;
    height: 30vh;
    flex-direction: column;
  }
  .pack_details {
    width: 100%;
  }
  .status_box {
    width: 24vh;
    height: 70%;
    background: #00d2ff;
  }

  .pack_box1_ttitle span {
    color: #191970;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .status_box1_ttitl span {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
  }
  .pack_box1_cat span {
    color: #191970;
    font-size: 0.8rem;
    font-weight: 500;
    padding-left: 2vh;
  }
  .margins_tops {
    margin-top: 3vh;
  }
  .trav_details {
    width: 100%;
  }
  .trav_display_main {
    width: 80%;
    height: 5vh;
    /* background: #00d2ff; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .input_divs {
    width: 100%;
    margin-top: 3vh;
  }
  .logo-bar {
    display: none;
  }
  .profile_container_header {
    display: none;
  }
  .img-log {
    display: block;
  }
  .img-log img{
    height: 48px;
  }
  .rev_slider_wrapper{
    height: 200px !important;
    width: 100% !important;
  }
  .rev_slider{
    height: 200px !important;
    width: auto !important;
  }
  /* .tp-parallax-wrap{
    position: absolute;
    top: 120px !important;
  } */
  .first{
    position: absolute;
    top: -150px !important;
    height: 100% !important;
  }
  .trackdiv_main{
    height: 100%;
  }
  .trackdiv_home{
    height: auto;
  }
}

